import React from "react";

export const ToggleSvg =
    <svg width="24" height="22" viewBox="0 0 30 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M21.5997 25.8324L21.2663 23.5324C20.933 23.4435 20.5941 23.3046 20.2497 23.1158C19.9052 22.9269 19.6219
            22.7102 19.3997 22.4658L17.433 22.9324L16.2997 20.9658L17.9663 19.4324C17.8997 19.2991 17.8719 19.0713 17.883
            18.7491C17.8941 18.4269 17.9219 18.2102 17.9663 18.0991L16.2997 16.5324L17.433 14.5991L19.3997 15.0324C19.6219
            14.8102 19.9052 14.5991 20.2497 14.3991C20.5941 14.1991 20.933 14.0658 21.2663 13.9991L21.5997 11.6658H24.033L24.3997
            13.9991C24.7108 14.0658 25.0441 14.1991 25.3997 14.3991C25.7552 14.5991 26.0441 14.8102 26.2663 15.0324L28.1997
            14.5991L29.3663 16.5324L27.6663 18.0991C27.7552 18.2324 27.7941 18.4546 27.783 18.7658C27.7719 19.0769 27.733
            19.2991 27.6663 19.4324L29.3663 20.9658L28.1997 22.9324L26.2663 22.4658C26.0441 22.7102 25.7552 22.9269 25.3997
            23.1158C25.0441 23.3046 24.7108 23.4435 24.3997 23.5324L24.033 25.8324H21.5997ZM0.833008 24.7991V20.7658C0.833008
            19.7435 1.10523 18.8658 1.64967 18.1324C2.19412 17.3991 2.83301 16.8546 3.56634 16.4991C5.16634 15.788 6.71079
            15.2658 8.19967 14.9324C9.68856 14.5991 11.0663 14.4324 12.333 14.4324H13.633C12.8108 15.8991 12.433 17.6991
            12.4997 19.8324C12.5663 21.9658 13.1552 23.6213 14.2663 24.7991H0.833008ZM22.833 21.2324C23.5663 21.2324 24.1608
            21.0046 24.6163 20.5491C25.0719 20.0935 25.2997 19.488 25.2997 18.7324C25.2997 17.9991 25.0719 17.4046 24.6163
            16.9491C24.1608 16.4935 23.5663 16.2658 22.833 16.2658C22.0997 16.2658 21.4997 16.4935 21.033 16.9491C20.5663
            17.4046 20.333 17.9991 20.333 18.7324C20.333 19.488 20.5663 20.0935 21.033 20.5491C21.4997 21.0046 22.0997 21.2324
            22.833 21.2324ZM12.333 12.3991C10.6219 12.3991 9.2219 11.8546 8.13301 10.7658C7.04412 9.67687 6.49967 8.27687
            6.49967 6.56575C6.49967 4.85464 7.04412 3.45464 8.13301 2.36576C9.2219 1.27687 10.6219 0.732422 12.333 0.732422C14.0441
            0.732422 15.4497 1.27687 16.5497 2.36576C17.6497 3.45464 18.1997 4.85464 18.1997 6.56575C18.1997 8.27687 17.6497
            9.67687 16.5497 10.7658C15.4497 11.8546 14.0441 12.3991 12.333 12.3991Z"
            fill="black"/>
    </svg>
;

export const CrossSvg =
    <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.9287 18L9.41204 10L1.89538 18" stroke="#2F2F2F" strokeWidth="3"/>
        <path d="M1.89551 2L9.41218 10L16.9288 2" stroke="#2F2F2F" strokeWidth="3"/>
    </svg>
;

export const IndicatorOptionsSvg =
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M5.46667 18.7667H7.46667V9.6H5.46667V18.7667ZM11 18.7667H13V5.23333H11V18.7667ZM16.5333 18.7667H18.5333V13.8333H16.5333V18.7667ZM2
            24C1.46667 24 1 23.8 0.6 23.4C0.2 23 0 22.5333 0 22V2C0 1.46667 0.2 1 0.6 0.6C1 0.2 1.46667 0 2 0H22C22.5333 0 23 0.2 23.4
             0.6C23.8 1 24 1.46667 24 2V22C24 22.5333 23.8 23 23.4 23.4C23 23.8 22.5333 24 22 24H2Z"
            fill="#707070"/>
    </svg>
;


export const TrashBinSvg =
    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M2.20938 16C1.85938 16 1.55312 15.8667 1.29062 15.6C1.02812 15.3333 0.896875 15.0222 0.896875
            14.6667V2H0V0.666667H4.1125V0H9.8875V0.666667H14V2H13.1031V14.6667C13.1031 15.0222 12.9719 15.3333
            12.7094 15.6C12.4469 15.8667 12.1406 16 11.7906 16H2.20938ZM4.52813 12.7556H5.84063V3.88889H4.52813V12.7556ZM8.15938
             12.7556H9.47188V3.88889H8.15938V12.7556Z"
            fill="#707070"/>
    </svg>
;

export const EyeIcon =
    <svg width="20" height="20" viewBox="0 0 576 448" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M288 0C207.2 0 142.5 36.8 95.4004 80.6C48.6004 124 17.3004 176 2.50039 211.7C-0.799609 219.6 -0.799609 228.4 2.50039 236.3C17.3004 272 48.6004 324 95.4004 367.4C142.5 411.2 207.2 448 288 448C368.8 448 433.5 411.2 480.6 367.4C527.4 323.9 558.7 272 573.6 236.3C576.9 228.4 576.9 219.6 573.6 211.7C558.7 176 527.4 124 480.6 80.6C433.5 36.8 368.8 0 288 0ZM432 224C432 303.5 367.5 368 288 368C208.5 368 144 303.5 144 224C144 144.5 208.5 80 288 80C367.5 80 432 144.5 432 224ZM288 160C288 195.3 259.3 224 224 224C212.5 224 201.7 221 192.4 215.6C192.2 218.4 192 221.1 192 224C192 277 235 320 288 320C341 320 384 277 384 224C384 171 341 128 288 128C285.2 128 282.4 128.1 279.6 128.4C284.9 137.7 288 148.5 288 160Z" fill="#707070"/>
    </svg>
;

export const EyeCloseIcon =
    <svg width="20" height="20" viewBox="0 0 640 512" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M38.7998 5.10171C28.3998 -3.09829 13.2998 -1.19829 5.09976 9.20171C-3.10024 19.6017 -1.20024 34.7017 9.19976 42.9017L601.2 506.902C611.6 515.102 626.7 513.202 634.9 502.802C643.1 492.402 641.2 477.302 630.8 469.102L525.6 386.702C565.2 346.102 592 300.602 605.5 268.302C608.8 260.402 608.8 251.602 605.5 243.702C590.6 208.002 559.3 156.002 512.5 112.602C465.5 68.8017 400.8 32.0017 320 32.0017C251.8 32.0017 195 58.3017 150.7 92.8017L38.7998 5.10171ZM223.1 149.502C248.6 126.202 282.7 112.002 320 112.002C399.5 112.002 464 176.502 464 256.002C464 280.902 457.7 304.302 446.6 324.702L408 294.502C413.2 282.702 416 269.702 416 256.002C416 203.002 373 160.002 320 160.002C317.2 160.002 314.4 160.102 311.6 160.402C316.9 169.702 320 180.502 320 192.002C320 202.202 317.6 211.802 313.4 220.302L223.1 149.502ZM446.2 447.502L373 389.902C356.6 396.402 338.7 400.002 320 400.002C240.5 400.002 176 335.502 176 256.002C176 249.102 176.5 242.402 177.4 235.802L83.0998 161.502C60.2998 191.202 43.9998 220.802 34.4998 243.702C31.1998 251.602 31.1998 260.402 34.4998 268.302C49.3998 304.002 80.6998 356.002 127.5 399.402C174.5 443.202 239.2 480.002 320 480.002C367.8 480.002 409.9 467.102 446.2 447.502Z" fill="#00AC87"/>
    </svg>
;

export const LockButtonInTextSvg =
    <svg width="14" height="12" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11 4.5C11 3.11875 12.1187 2 13.5 2C14.8813 2 16 3.11875 16 4.5V6C16 6.55312 16.4469 7 17
            7C17.5531 7 18 6.55312 18 6V4.5C18 2.01562 15.9844 0 13.5 0C11.0156 0 9 2.01562 9 4.5V6H2C0.896875
            6 0 6.89687 0 8V14C0 15.1031 0.896875 16 2 16H12C13.1031 16 14 15.1031 14 14V8C14 6.89687 13.1031 6 12 6H11V4.5Z"
            fill="#707070"/>
    </svg>
;

export const LockButtonSvg =
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1090_265)">
          <path d="M16 8.5C16 7.11875 17.1187 6 18.5 6C19.8813 6 21 7.11875 21 8.5V10C21 10.5531 21.4469 11 22
          11C22.5531 11 23 10.5531 23 10V8.5C23 6.01562 20.9844 4 18.5 4C16.0156 4 14 6.01562 14 8.5V10H7C5.89688
          10 5 10.8969 5 12V18C5 19.1031 5.89688 20 7 20H17C18.1031 20 19 19.1031 19 18V12C19 10.8969 18.1031 10 17 10H16V8.5Z"
                fill="#707070"/>
      </g>
      <defs>
          <clipPath id="clip0_1090_265">
              <rect width="18" height="16" fill="white" transform="translate(5 4)"/>
          </clipPath>
      </defs>
    </svg>
;

export const LockedButtonSvg =
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.5 8.5V10H14.5V8.5C14.5 7.11875 13.3813 6 12 6C10.6187 6 9.5 7.11875 9.5 8.5ZM7.5 10V8.5C7.5
         6.01562 9.51562 4 12 4C14.4844 4 16.5 6.01562 16.5 8.5V10H17C18.1031 10 19 10.8969 19 12V18C19 19.1031
          18.1031 20 17 20H7C5.89688 20 5 19.1031 5 18V12C5 10.8969 5.89688 10 7 10H7.5Z" fill="#00AC87"/>
    </svg>

;

export const UnavailableLockButtonSvg =
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
          d="M9.5 8.5V10H14.5V8.5C14.5 7.11875 13.3813 6 12 6C10.6187 6 9.5 7.11875 9.5 8.5ZM7.5 10V8.5C7.5
          6.01562 9.51562 4 12 4C14.4844 4 16.5 6.01562 16.5 8.5V10H17C18.1031 10 19 10.8969 19 12V18C19 19.1031
          18.1031 20 17 20H7C5.89688 20 5 19.1031 5 18V12C5 10.8969 5.89688 10 7 10H7.5Z"
          fill="#707070"/>
      <path
          d="M18 13C21.3141 13 24 10.3141 24 7C24 3.68594 21.3141 1 18 1C14.6859 1 12 3.68594 12 7C12 10.3141 14.6859 13 18 13Z"
          fill="#FE7919"/>
      <path
          d="M18.75 3.75C18.75 3.33437 18.4156 3 18 3C17.5844 3 17.25 3.33437 17.25 3.75V7.25C17.25 7.66563
          17.5844 8 18 8C18.4156 8 18.75 7.66563 18.75 7.25V3.75Z"
          fill="white"/>
      <path
          d="M18 11C18.5531 11 19 10.5531 19 10C19 9.44688 18.5531 9 18 9C17.4469 9 17 9.44688 17 10C17 10.5531 17.4469 11 18 11Z"
          fill="white"/>
    </svg>
;
