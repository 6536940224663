export {baseEncodeEntry} from './base';
export {color} from './color';
export {wrapCondition} from './conditional';
export {defined, valueIfDefined} from './defined';
export {nonPosition} from './nonposition';
export {pointPosition} from './position-point';
export {pointOrRangePosition, rangePosition} from './position-range';
export {rectBinPosition, rectPosition} from './position-rect';
export {text} from './text';
export {tooltip, tooltipRefForEncoding} from './tooltip';
export {aria} from './aria';
