import React from "react";
import {styled} from "@mui/system";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";

const Watermark = (props) => {
    const WatermarkContainer = styled(Box)(({theme}) => ({
        width: 'auto',
        padding: '10px',
        display: 'flex',
        paddingLeft: 0
    }));

    const Logo = styled(Link)(({theme}) => ({}));

    const logoSvg = <svg width="95" height="31" viewBox="0 0 95 31" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="94" height="30" rx="2.5" fill="white" stroke="#DCDCDC"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M5.09636 13.431C4.86391 13.8378 5.09055 14.3548 5.54423 14.4527L10.8808 15.6045C11.2793 15.6905 11.6878 15.5067 11.892 15.1494C12.0982 14.7885 12.0484 14.3344 11.7691 14.0289L11.094 13.2906C10.3551 12.4825 11.0927 11.1919 12.1548 11.4343L13.1316 11.6573C13.5294 11.7481 13.9398 11.5662 14.1441 11.2087C14.3461 10.8552 14.3004 10.4109 14.0309 10.108L13.6699 9.70223L13.4743 10.3458C13.3853 10.6386 13.1149 10.8388 12.8085 10.8388C12.3357 10.8388 12.0006 10.3782 12.1468 9.92932L12.609 8.5099L10.3814 6.00642C10.0683 5.65453 9.50623 5.7148 9.27155 6.12542C9.11943 6.39161 9.15721 6.72676 9.36458 6.95077L10.0757 7.7189C10.8252 8.52859 10.0783 9.83169 9.01003 9.57798L8.12481 9.36773C7.75568 9.28006 7.37289 9.44765 7.18289 9.78011C6.99289 10.1126 7.04062 10.5313 7.30034 10.8104L7.92318 11.4797C8.6748 12.2874 7.93117 13.5925 6.86228 13.3415L5.84412 13.1025C5.55086 13.0337 5.24721 13.1671 5.09636 13.431Z"
              fill="#FF5B39"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M8.82571 15.1616L10.3585 15.4924L10.1129 15.7577C9.36335 16.5674 10.1103 17.8705 11.1785 17.6168L12.0638 17.4066C12.4329 17.3189 12.8157 17.4865 13.0057 17.8189C13.1957 18.1514 13.1479 18.5701 12.8882 18.8492L12.2654 19.5185C11.5138 20.3263 12.2574 21.6313 13.3263 21.3804L14.3403 21.1423C14.636 21.0729 14.9422 21.2074 15.0943 21.4736C15.329 21.8842 15.0996 22.4061 14.6414 22.5041L9.30056 23.6462C8.90615 23.7306 8.50238 23.5484 8.30038 23.1949C8.09605 22.8374 8.14539 22.3874 8.42211 22.0848L9.10161 21.3417C9.84051 20.5337 9.1029 19.243 8.04075 19.4854L7.07022 19.707C6.66873 19.7986 6.2545 19.6151 6.04826 19.2542C5.84405 18.8969 5.89068 18.4476 6.16371 18.142L8.82571 15.1616Z"
              fill="#39AD84"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M21.2105 26.9517C21.6787 26.9517 22.011 26.4959 21.8671 26.051L20.1741 20.818C20.0476 20.4272 19.6832 20.1624 19.2718 20.1624C18.8564 20.1624 18.4893 20.4324 18.3662 20.8286L18.0687 21.7863C17.7431 22.8344 16.2573 22.8344 15.9317 21.7863L15.6323 20.8224C15.5104 20.4299 15.1467 20.1624 14.7351 20.1624C14.3281 20.1624 13.9675 20.4239 13.8417 20.8103L13.6826 21.299L14.3382 21.145C14.6339 21.0756 14.9401 21.2102 15.0923 21.4763C15.3269 21.887 15.0976 22.4088 14.6393 22.5068L13.1882 22.8171L12.1381 26.0422C11.992 26.4911 12.3271 26.9517 12.7998 26.9517C13.1062 26.9517 13.3766 26.7515 13.4656 26.4587L13.7707 25.4548C14.0924 24.3966 15.5937 24.3985 15.9126 25.4575L16.1769 26.3352C16.2871 26.7011 16.6246 26.9517 17.0073 26.9517C17.39 26.9517 17.7275 26.7011 17.8377 26.3352L18.102 25.4575C18.4209 24.3985 19.9222 24.3966 20.2439 25.4548L20.5502 26.4628C20.6385 26.7532 20.9066 26.9517 21.2105 26.9517Z"
              fill="#FCB03F"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M20.8176 22.8219L20.3301 21.315L20.6512 21.3904C21.7201 21.6413 22.4637 20.3363 21.7121 19.5285L21.0892 18.8592C20.8295 18.5801 20.7818 18.1614 20.9718 17.8289C21.1618 17.4965 21.5446 17.3289 21.9137 17.4166L22.7989 17.6268C23.8672 17.8805 24.6141 16.5774 23.8645 15.7677L23.1535 14.9996C22.9461 14.7756 22.9083 14.4404 23.0604 14.1743C23.2951 13.7636 23.8572 13.7034 24.1703 14.0552L27.8198 18.1568C28.0893 18.4597 28.135 18.9041 27.933 19.2575C27.7287 19.6151 27.3183 19.7969 26.9205 19.7061L25.9437 19.4831C24.8816 19.2407 24.144 20.5314 24.8829 21.3394L25.558 22.0777C25.8373 22.3832 25.8871 22.8373 25.6809 23.1982C25.4767 23.5555 25.0682 23.7394 24.6697 23.6533L20.8176 22.8219Z"
              fill="#FF5B39"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M24.7336 6.1194C24.5011 5.71268 23.9446 5.65247 23.6339 6.00042L19.9782 10.0934C19.7051 10.3991 19.6585 10.8483 19.8627 11.2056C20.0689 11.5665 20.4832 11.75 20.8847 11.6584L21.8552 11.4369C22.9174 11.1944 23.655 12.4851 22.9161 13.2931L22.2366 14.0362C21.9598 14.3388 21.9105 14.7888 22.1148 15.1463C22.3168 15.4998 22.7206 15.682 23.115 15.5976L28.4559 14.4555C28.9141 14.3575 29.1435 13.8357 28.9088 13.425C28.7567 13.1588 28.4505 13.0243 28.1547 13.0937L27.1407 13.3318C26.0718 13.5827 25.3282 12.2777 26.0798 11.4699L26.7027 10.8006C26.9624 10.5215 27.0101 10.1028 26.8201 9.77035C26.6301 9.43789 26.2473 9.2703 25.8782 9.35797L24.993 9.56822C23.9247 9.82193 23.1778 8.51883 23.9274 7.70913L24.6413 6.93786C24.847 6.71572 24.8844 6.38336 24.7336 6.1194ZM23.1629 15.0009L23.6125 15.4864L25.158 15.1559L24.1798 14.0565C23.8667 13.7046 23.3046 13.7649 23.0699 14.1755C22.9178 14.4417 22.9556 14.7768 23.1629 15.0009Z"
              fill="#39AD84"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M20.3466 9.67885L20.2439 9.34078C19.9222 8.28254 18.4209 8.28447 18.102 9.34354L17.8377 10.2211C17.7275 10.5871 17.39 10.8376 17.0073 10.8376C16.6246 10.8376 16.2871 10.5871 16.1769 10.2211L15.9126 9.34354C15.5937 8.28447 14.0924 8.28254 13.7707 9.34078L13.4656 10.3447C13.3766 10.6375 13.1062 10.8376 12.7998 10.8376C12.3271 10.8376 11.992 10.3771 12.1381 9.9282L13.8417 4.69628C13.9675 4.30991 14.3281 4.04834 14.7351 4.04834C15.1467 4.04834 15.5104 4.31589 15.6323 4.70842L15.9317 5.67228C16.2573 6.72039 17.7431 6.72039 18.0687 5.67228L18.3662 4.71457C18.4893 4.31839 18.8564 4.04834 19.2718 4.04834C19.6832 4.04834 20.0476 4.31318 20.1741 4.70401L21.4015 8.49785L20.3466 9.67885Z"
              fill="#FCB03F"/>
        <g clipPath="url(#clip0_1001_40)">
            <path
                d="M40.077 20.1287L38.7789 15.8137H37.3254L36.0274 20.1287L34.719 15.8137H33L35.1648 22.5154H36.7075L38.0573 18.1589L39.4093 22.5154H40.9396L43.1168 15.8137H41.3854L40.077 20.1287Z"
                fill="#003047"/>
            <path
                d="M46.6184 15.6251C46.1949 15.627 45.7724 15.6659 45.3556 15.7412C44.9851 15.7986 44.6235 15.9031 44.2795 16.0523L44.182 16.0979V17.5494L44.4184 17.4415C44.7235 17.3063 45.0427 17.2055 45.3701 17.1409C45.7326 17.0675 46.1014 17.0307 46.4712 17.031C47.0933 17.031 47.5349 17.1429 47.8086 17.3607C47.9476 17.4833 48.0559 17.6369 48.1247 17.8091C48.1935 17.9812 48.2209 18.1671 48.2047 18.3518V18.4099C47.5534 18.1176 46.8447 17.9759 46.1311 17.9952C45.2727 17.9952 44.5946 18.2025 44.1198 18.6172C43.8745 18.8384 43.6818 19.1116 43.5558 19.417C43.4299 19.7223 43.3739 20.052 43.392 20.3818C43.3787 20.7078 43.4397 21.0325 43.5705 21.3314C43.7013 21.6303 43.8983 21.8955 44.1468 22.107C44.7037 22.5435 45.3995 22.7644 46.1063 22.729C46.5965 22.7406 47.0844 22.6576 47.5432 22.4844C47.8126 22.3736 48.0636 22.2226 48.2876 22.0365V22.53H49.8801V18.4078C49.8801 17.5411 49.6064 16.8506 49.0631 16.355C48.5198 15.8594 47.7091 15.6251 46.6184 15.6251ZM48.2047 19.8116V20.5373C48.0427 20.7995 47.8068 21.008 47.5266 21.1366C47.1891 21.2959 46.8196 21.3761 46.4463 21.3709C45.2271 21.3709 45.0861 20.8007 45.0861 20.3694C45.0727 20.224 45.0944 20.0776 45.1492 19.9423C45.204 19.807 45.2905 19.6868 45.4012 19.5918C45.621 19.4155 45.9735 19.3285 46.438 19.3285C47.0596 19.3174 47.6717 19.4812 48.2047 19.8012V19.8116Z"
                fill="#003047"/>
            <path
                d="M56.7897 16.9146V15.8136H51.243V17.1801H54.6042L51.1621 21.4287V22.5153H56.9224V21.1488H53.3497L56.7897 16.9146Z"
                fill="#003047"/>
            <path
                d="M59.2114 13C58.9595 13 58.718 13.1 58.5399 13.2781C58.3618 13.4562 58.2617 13.6978 58.2617 13.9497C58.2617 14.2015 58.3618 14.4431 58.5399 14.6212C58.718 14.7993 58.9595 14.8993 59.2114 14.8993C59.3393 14.9021 59.4664 14.8794 59.5853 14.8324C59.7042 14.7854 59.8126 14.7151 59.904 14.6256C59.994 14.5376 60.0655 14.4324 60.1144 14.3163C60.1632 14.2003 60.1884 14.0756 60.1884 13.9497C60.1884 13.8237 60.1632 13.6991 60.1144 13.583C60.0655 13.4669 59.994 13.3617 59.904 13.2737C59.8126 13.1842 59.7042 13.114 59.5853 13.067C59.4664 13.02 59.3393 12.9972 59.2114 13V13Z"
                fill="#003047"/>
            <path d="M60.0674 15.8138H58.3691V22.5155H60.0674V15.8138Z" fill="#003047"/>
            <path
                d="M69.604 15.6251C69.1532 15.6275 68.7078 15.7228 68.2956 15.905C67.9453 16.0541 67.6282 16.2711 67.3625 16.5437C67.2187 16.2775 67.0032 16.0569 66.7404 15.9071C66.3374 15.7019 65.888 15.6047 65.4362 15.6251C65.0008 15.6261 64.5714 15.7275 64.1817 15.9216C63.8929 16.0647 63.6284 16.2522 63.3979 16.4773V15.8138H61.793V22.5154H63.4394V18.0491C63.9266 17.3669 64.4761 17.0123 65.0775 17.0123C65.4922 17.0123 65.7617 17.1014 65.9069 17.2777C66.052 17.4539 66.1349 17.8002 66.1349 18.2813V22.5071H67.7668V18.3808C67.7668 18.1175 67.7668 17.9184 67.7461 17.7774C67.9115 17.5406 68.1323 17.3478 68.3892 17.2159C68.6462 17.084 68.9315 17.0169 69.2204 17.0206C69.6351 17.0206 69.9316 17.1118 70.0913 17.2922C70.2509 17.4726 70.3422 17.8127 70.3422 18.2896V22.5154H71.974V18.1133C71.974 17.3047 71.7853 16.6805 71.4142 16.2472C71.043 15.8138 70.4272 15.6251 69.604 15.6251Z"
                fill="#003047"/>
            <path
                d="M76.2644 15.6251C75.8409 15.627 75.4184 15.6659 75.0016 15.7412C74.6312 15.7989 74.2696 15.9034 73.9255 16.0523L73.8301 16.0979V17.5494L74.0644 17.4415C74.3702 17.3063 74.6901 17.2055 75.0182 17.1409C75.3806 17.0674 75.7495 17.0306 76.1193 17.031C76.7413 17.031 77.1809 17.1429 77.4546 17.3607C77.7283 17.5784 77.8507 17.8977 77.8507 18.3518V18.4099C77.2026 18.119 76.4976 17.9773 75.7875 17.9952C74.927 17.9952 74.251 18.2025 73.7741 18.6172C73.5288 18.8384 73.3361 19.1116 73.2101 19.417C73.0842 19.7223 73.0282 20.052 73.0463 20.3818C73.0334 20.7079 73.0948 21.0327 73.2259 21.3316C73.3571 21.6305 73.5544 21.8956 73.8031 22.107V22.107C74.3593 22.5436 75.0544 22.7645 75.7605 22.729C76.2519 22.7354 76.7398 22.6466 77.1975 22.4678C77.4673 22.3565 77.7189 22.2056 77.944 22.0199V22.5134H79.5365V18.4078C79.5365 17.5411 79.2607 16.8506 78.7195 16.355C78.1783 15.8594 77.3551 15.6251 76.2644 15.6251ZM77.8507 19.8116V20.5373C77.6895 20.7995 77.4543 21.008 77.1747 21.1366C76.8364 21.296 76.4662 21.3761 76.0923 21.3709C74.8731 21.3709 74.7321 20.8007 74.7321 20.3694C74.7193 20.2241 74.7411 20.0778 74.7959 19.9426C74.8507 19.8074 74.9369 19.6872 75.0473 19.5918C75.3549 19.3934 75.719 19.3009 76.084 19.3285C76.7056 19.3174 77.3177 19.4812 77.8507 19.8012V19.8116Z"
                fill="#003047"/>
            <path
                d="M88.2528 17.3109C87.9567 16.7876 87.5209 16.3568 86.9942 16.0668C86.4442 15.7679 85.8264 15.6158 85.2006 15.6251C84.2866 15.5938 83.3956 15.9153 82.7123 16.523V15.8138H81.0742V25.4226H82.7476V21.8976C83.0142 22.1278 83.3196 22.309 83.6496 22.4326C84.1433 22.6183 84.6669 22.7117 85.1943 22.7083C85.8248 22.719 86.4473 22.5654 87.0004 22.2625C87.524 21.9668 87.9549 21.5309 88.2445 21.0039C88.5415 20.4326 88.6965 19.7982 88.6965 19.1543C88.6965 18.5104 88.5415 17.876 88.2445 17.3047L88.2528 17.3109ZM83.5562 17.4167C83.9497 17.1513 84.415 17.0131 84.8895 17.0206C85.2707 17.0155 85.6472 17.1061 85.9844 17.284C86.3027 17.4562 86.5658 17.715 86.7433 18.0304C86.9317 18.3776 87.0267 18.7677 87.0191 19.1626C87.0265 19.5601 86.9316 19.9529 86.7433 20.303C86.5666 20.6201 86.3034 20.8805 85.9844 21.0537V21.0537C85.6477 21.2333 85.271 21.3246 84.8895 21.3191C84.4069 21.3238 83.9346 21.1789 83.5376 20.9044C83.161 20.6526 82.8822 20.2793 82.7476 19.8469V18.47C82.9004 18.043 83.1832 17.6746 83.5562 17.4167V17.4167Z"
                fill="#003047"/>
        </g>
        <path
            d="M35.8657 9.02473H34.5142V8.5H35.8657C36.1274 8.5 36.3394 8.45989 36.5015 8.37968C36.6636 8.29947 36.7817 8.18806 36.8559 8.04545C36.9323 7.90285 36.9705 7.7402 36.9705 7.55749C36.9705 7.39037 36.9323 7.23329 36.8559 7.08623C36.7817 6.93917 36.6636 6.82108 36.5015 6.73195C36.3394 6.6406 36.1274 6.59492 35.8657 6.59492H34.6705V10.9332H34V6.06684H35.8657C36.2479 6.06684 36.571 6.13035 36.835 6.25735C37.0991 6.38436 37.2994 6.56038 37.4361 6.78543C37.5727 7.00824 37.6411 7.26337 37.6411 7.5508C37.6411 7.86275 37.5727 8.12901 37.4361 8.3496C37.2994 8.57019 37.0991 8.73841 36.835 8.85428C36.571 8.96791 36.2479 9.02473 35.8657 9.02473Z"
            fill="#003047"/>
        <path
            d="M43.6642 8.34626V8.65374C43.6642 9.01916 43.6167 9.3467 43.5218 9.63636C43.4268 9.92603 43.2901 10.1722 43.1118 10.375C42.9335 10.5778 42.7192 10.7326 42.4691 10.8396C42.2212 10.9465 41.9433 11 41.6352 11C41.3364 11 41.062 10.9465 40.8118 10.8396C40.564 10.7326 40.3486 10.5778 40.1656 10.375C39.9849 10.1722 39.8448 9.92603 39.7452 9.63636C39.6456 9.3467 39.5958 9.01916 39.5958 8.65374V8.34626C39.5958 7.98084 39.6445 7.65441 39.7417 7.36698C39.8413 7.07732 39.9815 6.83111 40.1621 6.62834C40.3428 6.42335 40.557 6.26738 40.8049 6.16043C41.055 6.05348 41.3295 6 41.6283 6C41.9363 6 42.2143 6.05348 42.4621 6.16043C42.7123 6.26738 42.9265 6.42335 43.1049 6.62834C43.2855 6.83111 43.4233 7.07732 43.5183 7.36698C43.6156 7.65441 43.6642 7.98084 43.6642 8.34626ZM43.0006 8.65374V8.33957C43.0006 8.04991 42.9694 7.79367 42.9068 7.57086C42.8466 7.34804 42.7574 7.16087 42.6393 7.00936C42.5212 6.85784 42.3764 6.74309 42.205 6.66511C42.0359 6.58712 41.8437 6.54813 41.6283 6.54813C41.4198 6.54813 41.2311 6.58712 41.062 6.66511C40.8952 6.74309 40.7516 6.85784 40.6312 7.00936C40.513 7.16087 40.4215 7.34804 40.3567 7.57086C40.2918 7.79367 40.2594 8.04991 40.2594 8.33957V8.65374C40.2594 8.94563 40.2918 9.2041 40.3567 9.42914C40.4215 9.65196 40.5142 9.84024 40.6346 9.99398C40.7574 10.1455 40.9022 10.2602 41.0689 10.3382C41.238 10.4162 41.4268 10.4552 41.6352 10.4552C41.853 10.4552 42.0464 10.4162 42.2154 10.3382C42.3845 10.2602 42.527 10.1455 42.6428 9.99398C42.7609 9.84024 42.8501 9.65196 42.9103 9.42914C42.9705 9.2041 43.0006 8.94563 43.0006 8.65374Z"
            fill="#003047"/>
        <path
            d="M47.2797 9.46257L48.2838 6.06684H48.7702L48.4887 7.38703L47.4082 10.9332H46.9253L47.2797 9.46257ZM46.2409 6.06684L47.04 9.39572L47.2797 10.9332H46.8002L45.5738 6.06684H46.2409ZM50.0695 9.39238L50.8513 6.06684H51.5218L50.2989 10.9332H49.8194L50.0695 9.39238ZM48.8431 6.06684L49.8194 9.46257L50.1738 10.9332H49.6909L48.6486 7.38703L48.3637 6.06684H48.8431Z"
            fill="#003047"/>
        <path
            d="M56.7598 10.4084V10.9332H54.0811V10.4084H56.7598ZM54.2166 6.06684V10.9332H53.5461V6.06684H54.2166ZM56.4054 8.15909V8.68382H54.0811V8.15909H56.4054ZM56.725 6.06684V6.59492H54.0811V6.06684H56.725Z"
            fill="#003047"/>
        <path
            d="M58.8709 6.06684H60.5455C60.9254 6.06684 61.2461 6.12255 61.5079 6.23396C61.7719 6.34537 61.9723 6.51025 62.1089 6.72861C62.2479 6.94474 62.3174 7.21101 62.3174 7.52741C62.3174 7.75022 62.2699 7.9541 62.1749 8.13904C62.0823 8.32175 61.9479 8.47772 61.7719 8.60695C61.5982 8.73396 61.3897 8.82865 61.1465 8.89104L60.9589 8.96123H59.3851L59.3781 8.4365H60.5663C60.8072 8.4365 61.0076 8.39639 61.1674 8.31618C61.3272 8.23373 61.4477 8.12344 61.5287 7.98529C61.6098 7.84715 61.6503 7.69452 61.6503 7.52741C61.6503 7.34024 61.6121 7.17647 61.5357 7.0361C61.4592 6.89572 61.3388 6.78766 61.1743 6.7119C61.0122 6.63391 60.8026 6.59492 60.5455 6.59492H59.5414V10.9332H58.8709V6.06684ZM61.8275 10.9332L60.5976 8.72727L61.2959 8.72393L62.5432 10.893V10.9332H61.8275Z"
            fill="#003047"/>
        <path
            d="M67.7499 10.4084V10.9332H65.0712V10.4084H67.7499ZM65.2067 6.06684V10.9332H64.5362V6.06684H65.2067ZM67.3955 8.15909V8.68382H65.0712V8.15909H67.3955ZM67.7152 6.06684V6.59492H65.0712V6.06684H67.7152Z"
            fill="#003047"/>
        <path
            d="M71.2299 10.9332H70.1737L70.1807 10.4084H71.2299C71.5912 10.4084 71.8923 10.336 72.1332 10.1912C72.3741 10.0441 72.5548 9.83913 72.6752 9.5762C72.798 9.31105 72.8593 9.00134 72.8593 8.64706V8.3496C72.8593 8.07108 72.8246 7.82375 72.7551 7.60762C72.6856 7.38926 72.5837 7.20544 72.4494 7.05615C72.315 6.90463 72.1506 6.78988 71.956 6.7119C71.7638 6.63391 71.5426 6.59492 71.2924 6.59492H70.1529V6.06684H71.2924C71.6237 6.06684 71.9259 6.12032 72.1992 6.22727C72.4725 6.332 72.7076 6.48463 72.9045 6.68516C73.1037 6.88347 73.2566 7.12411 73.3631 7.40709C73.4697 7.68783 73.5229 8.00423 73.5229 8.35628V8.64706C73.5229 8.99911 73.4697 9.31662 73.3631 9.5996C73.2566 9.88035 73.1025 10.1199 72.901 10.3182C72.7018 10.5165 72.461 10.6691 72.1784 10.7761C71.8981 10.8808 71.582 10.9332 71.2299 10.9332ZM70.5316 6.06684V10.9332H69.861V6.06684H70.5316Z"
            fill="#003047"/>
        <path
            d="M80.6601 8.65709H79.3781L79.3711 8.13904H80.535C80.7273 8.13904 80.8952 8.10784 81.0388 8.04545C81.1824 7.98307 81.2936 7.89394 81.3723 7.77807C81.4534 7.65998 81.4939 7.51961 81.4939 7.35695C81.4939 7.1787 81.458 7.03387 81.3862 6.92246C81.3167 6.80882 81.209 6.72638 81.0631 6.67513C80.9195 6.62166 80.7365 6.59492 80.5142 6.59492H79.5275V10.9332H78.8569V6.06684H80.5142C80.7736 6.06684 81.0052 6.09247 81.209 6.14372C81.4128 6.19274 81.5854 6.27072 81.7267 6.37767C81.8703 6.4824 81.9792 6.61609 82.0533 6.77874C82.1274 6.9414 82.1645 7.13636 82.1645 7.36364C82.1645 7.56417 82.1112 7.74577 82.0046 7.90842C81.8981 8.06885 81.7499 8.20031 81.5599 8.30281C81.3723 8.4053 81.1523 8.47103 80.8998 8.5L80.6601 8.65709ZM80.6288 10.9332H79.114L79.4927 10.4084H80.6288C80.8419 10.4084 81.0226 10.3728 81.1708 10.3015C81.3214 10.2302 81.436 10.1299 81.5148 10.0007C81.5935 9.86921 81.6329 9.71435 81.6329 9.5361C81.6329 9.35562 81.5993 9.19964 81.5321 9.06818C81.465 8.93672 81.3596 8.83534 81.216 8.76404C81.0724 8.69274 80.8871 8.65709 80.6601 8.65709H79.7047L79.7116 8.13904H81.0179L81.1604 8.3262C81.4036 8.34626 81.6097 8.4131 81.7788 8.52674C81.9479 8.63815 82.0764 8.78075 82.1645 8.95455C82.2548 9.12834 82.3 9.31996 82.3 9.52941C82.3 9.83244 82.2305 10.0887 82.0915 10.2981C81.9548 10.5053 81.7614 10.6635 81.5113 10.7727C81.2611 10.8797 80.967 10.9332 80.6288 10.9332Z"
            fill="#003047"/>
        <path
            d="M84.6091 6.06684L85.9224 8.51003L87.2391 6.06684H88L86.2559 9.11497V10.9332H85.5854V9.11497L83.8413 6.06684H84.6091Z"
            fill="#003047"/>
        <defs>
            <clipPath id="clip0_1001_40">
                <rect width="55.7055" height="12.4205" fill="white" transform="translate(33 13)"/>
            </clipPath>
        </defs>
    </svg>


    return (
        <WatermarkContainer>
            <Logo
                href={'https://wazimap.com/'}
                target={'_blank'}
            >
                {logoSvg}
            </Logo>
        </WatermarkContainer>
    );
}

export default Watermark;